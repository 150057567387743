import * as Sentry from "@sentry/react";
import { Coupon } from "../contexts/CouponContext";
import RitualAnalytics from "client-analytics";
import { PAYMENT_STATUSES } from "./pricing-content";

export enum PaymentType {
  Payment = "Payment",
  UpdatePaymentDetails = "Save card information",
}

export const convertPurchase = ({
  uuid,
  coupon,
  planId,
  tierType,
  originalPrice,
}: {
  uuid: string;
  planId: string;
  coupon?: Coupon;
  originalPrice: number;
  tierType: number;
}) => {
  const payload = {
    event: "checkout-completed",
    customerId: uuid,
    orderPromoCode: coupon?.name,
    orderDiscount: coupon
      ? coupon?.amount_off || (originalPrice * coupon.percentOff) / 100
      : 0,
    subTotal: originalPrice,
    category: planId,
    sku: tierType,
  };

  try {
    if (
      !window.location.origin.includes("dev") &&
      !window.location.origin.includes("localhost")
    ) {
      window.dataLayer.push(payload);
    }
  } catch (error) {
    Sentry.captureMessage(
      `Sending checkout-completed event for conversion of the purchase failed`,
      {
        extra: { data: payload },
        level: "error",
      },
    );
  }
};

export const trackError = (
  user_data: { [key: string]: any },
  errorMessage: string,
  eventName: string,
) => {
  if (!window.location.origin.includes("localhost")) {
    RitualAnalytics.track(eventName, {
      customer_data: user_data,
      error: errorMessage,
      status_code: PAYMENT_STATUSES.STRIPE_ERROR,
    });
  }
};

export const trackSuccess = (
  user_data: { [key: string]: any },
  eventName: string,
) => {
  if (!window.location.origin.includes("localhost")) {
    RitualAnalytics.track(eventName, {
      customer_data: user_data,
      error: "",
      status_code: PAYMENT_STATUSES.SUCCESS,
    });
  }
};
