import { getCookieValue } from "./getCookieValue";

export const RITUAL_MATCHING = process.env.REACT_APP_MATCHING_DOMAIN;
export const REACT_APP_WEBAPP_URL = process.env.REACT_APP_WEBAPP_DOMAIN;
export const PAYMENT_STATUS_SUCCESS = "succeeded";

export const getUrl = ({
  stripeId,
  postgresPlanId,
  initialPriceAmount,
  percentOff,
  customParams,
}: {
  stripeId: string;
  postgresPlanId: number;
  initialPriceAmount: number;
  percentOff?: number;
  customParams?: Record<string, string>;
}) => {
  const params = new URLSearchParams(window.location.search);
  const funnelStart = params.get("funnel_start") || "pricing";
  const funnelId = params.get("funnelid") || "tiers-v1";
  const vwoUuid = getCookieValue("_vwo_uuid") || "";

  params.set("vwo_uuid", vwoUuid);
  params.set("price", stripeId);
  params.set("postgres_plan_id", String(postgresPlanId));
  params.set("funnelid", funnelId);
  params.set("initial_amount", String(initialPriceAmount || 1));
  params.set("funnel_start", funnelStart);

  if (percentOff) params.set("percent_off", String(percentOff));

  customParams &&
    Object.entries(customParams).forEach(([key, value]) =>
      params.set(key, value),
    );

  return `?${params.toString()}`;
};
