import { AllPlansDescription, CmsImage } from "../api/types";
import { Plans } from "../hooks/useCurrentStep";
import { Coupon } from "../contexts/CouponContext";
import { Subscriptions } from "../hooks/useCurrentPlan";

export const CMS_URL =
  process.env.REACT_APP_CMS_URL?.split("api")[0] ||
  "https://cms.ourritual.com/";

const transformCmsImage = (image: CmsImage) =>
  image.data?.attributes?.url || "";

export const transformPlans = (
  plans: AllPlansDescription & { coverIcon: CmsImage; contentImage: CmsImage },
) => {
  const coverIcon = transformCmsImage(plans.coverIcon);
  const contentImage = transformCmsImage(plans.contentImage);
  return {
    ...plans,
    coverIcon,
    contentImage,
  };
};

export const getIsMatchingOrUpgrading = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const matching = queryParams.get("matching");
  const payment_mode = queryParams.get("mode");

  return matching || payment_mode;
};

export function getSubscriptionFromSearchParams(search: string) {
  const queryParams = new URLSearchParams(search);
  return queryParams.get("subscription") as Plans;
}

export function getPaymentModeFromSearchParams() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("mode");
}

export function getMatchingFromSearchParams(search: string) {
  const queryParams = new URLSearchParams(search);
  return queryParams.get("matching");
}

export function getEmailFromSearchParams() {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("email") || "";
}

export const getSavingsPercentage = (price1: number, price2: number) => {
  let smallerPrice = price1;
  let largerPrice = price2;

  if (price2 < price1) {
    smallerPrice = price2;
    largerPrice = price1;
  }

  return 100 - Math.ceil((smallerPrice * 100) / largerPrice);
};

export const tranformPlanTitle = (months: number) => {
  return `${months > 1 ? months : ""} Month${months > 1 ? "s" : "ly"} Plan`;
};

export const formatPrice = (price: number) => {
  return hasDigitsAfterDecimal(price) ? +price.toFixed(2) : price + ".00";
};

export const hasDigitsAfterDecimal = (num: number) => {
  const numStr = num.toString();
  return numStr.includes(".") && numStr.split(".")[1].length > 0;
};

export const getPriceWithCoupon = (originalPrice: number, coupon?: Coupon) => {
  let discountedPrice = originalPrice;

  if (coupon) {
    if (coupon.percentOff) {
      discountedPrice -= (originalPrice * coupon.percentOff) / 100;
    } else if (coupon.amount_off) {
      discountedPrice -= coupon.amount_off / 100;
    }
  }

  return +discountedPrice.toFixed(2);
};

export const getPlanTitle = (
  months?: number,
  planName?: string,
  frequency?: string,
) =>
  `${planName === Subscriptions.Individual ? Plans.Individual : planName} ${
    frequency || ""
  } ${
    planName !== Subscriptions.Matching && months
      ? tranformPlanTitle(months)
      : ""
  }`;

export const REACT_APP_STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  "pk_test_51KG0VADJtTREqYymJa7WwvpYi13qciB1iDHiNlh25HmyPZ44lgnypiPvyl5b4NjJgiPqBsYfCjiF1qhicGii8YU300y9ECAhBB";

export const REACT_APP_ONBOARDING_URL = process.env.REACT_APP_ONBOARDING_URL;
export const REACT_APP_CHECKOUT_COMPLETE_URL =
  process.env.REACT_APP_CHECKOUT_COMPLETE_URL;

export const REACT_APP_SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export enum PAYMENT_STATUSES {
  SUCCESS = 200,
  SERVER_ERROR = 500,
  NETWORK_ERROR = 502,
  STRIPE_ERROR = 700,
}

export const PAYMENT_ERROR_MESSAGES: { [key: number]: string } = {
  [PAYMENT_STATUSES.SERVER_ERROR]:
    "Something went wrong. Please try again. If the problem continues, contact support",
  [PAYMENT_STATUSES.NETWORK_ERROR]:
    "There has been a network issue. Please check your connection and try again.",
  [PAYMENT_STATUSES.STRIPE_ERROR]:
    "An issue occurred with the payment service. Please try again later.",
};
