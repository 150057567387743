import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  getCustomerData,
  getPriceFromQuery,
  getRedirectFromQuery,
} from "../../../utils/getCustomerData";
import {
  useCreateMatchingSessionSubscriptionMutation,
  useCreateMemberMutation,
  useCreatePaymentMutation,
} from "../../../api/management";
import { CouponContext } from "../../../contexts/CouponContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  REACT_APP_ONBOARDING_URL,
  PAYMENT_ERROR_MESSAGES,
  REACT_APP_CHECKOUT_COMPLETE_URL,
  PAYMENT_STATUSES,
} from "../../../utils/pricing-content";
import { getUrl, PAYMENT_STATUS_SUCCESS } from "../../../utils/redirect";
import { useGetPriceQuery } from "../../../api/stripe";
import { NEW_ROUTES } from "../../../router";
import PaymentDetailsForm from "../../../components/PaymentDetailsForm";
import useCurrentTier from "../../../hooks/useCurrentTier";
import Loading from "../../../components/Loading";
import { PaymentIntent, SetupIntent, StripeError } from "@stripe/stripe-js";
import {
  convertPurchase,
  trackError,
  trackSuccess,
} from "../../../utils/payment";

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [createPayment] = useCreatePaymentMutation();
  const [createMember] = useCreateMemberMutation();
  const [createMatchingSessionSubscription] =
    useCreateMatchingSessionSubscriptionMutation();
  const { coupon } = useContext(CouponContext);
  const { state } = useLocation();
  const { originalPrice } = useGetPriceQuery(
    state?.price || getPriceFromQuery(),
  );
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const tier = useCurrentTier();
  const redirect = getRedirectFromQuery();
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, stripe]);

  const handleRedirect = async () => {
    if (!redirect || !stripe) return;

    setIsRedirectLoading(true);
    const queryParams = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );

    const {
      uuid = "",
      email = "",
      first_name = "",
      last_name = "",
      phone_number = "",
      customer_id = "",
      postgres_plan_id: plan_uuid = "",
      payment_intent_client_secret: paymentIntentSecret = "",
      setup_intent_client_secret: setupIntentSecret = "",
      matching_session_purchase: matchingSessionPurchase = "",
    } = queryParams;

    const user_data = {
      email,
      first_name,
      last_name,
      phone_number,
      uuid,
      plan_uuid,
      customer_id,
      status: 5,
      age: 0,
      expert: "expert@heyritual.com",
    };

    try {
      let paymentInfo: PaymentIntent | SetupIntent | undefined;
      let retrievalError: StripeError | undefined;

      if (paymentIntentSecret) {
        const { paymentIntent, error } =
          await stripe.retrievePaymentIntent(paymentIntentSecret);
        paymentInfo = paymentIntent;
        retrievalError = error;
      } else {
        const { setupIntent, error } =
          await stripe.retrieveSetupIntent(setupIntentSecret);
        paymentInfo = setupIntent;
        retrievalError = error;
      }

      const eventName = `member-payment-confirmation-${
        window.location.origin.includes("dev") ? "dev" : "prod"
      }`;

      if (retrievalError) {
        trackError(user_data, retrievalError?.message || "", eventName);
        return handleError({ error: retrievalError.message });
      }

      if (paymentInfo?.status === PAYMENT_STATUS_SUCCESS) {
        convertPurchase({
          uuid,
          planId: plan_uuid,
          coupon,
          originalPrice,
          tierType: tier?.tier_type,
        });
        trackSuccess(user_data, eventName);

        if (matchingSessionPurchase) {
          await createMatchingSessionSubscription(user_data);
        }

        await createMember(user_data).unwrap();
        window.location.href =
          REACT_APP_ONBOARDING_URL + window.location.search;
      } else {
        handleError({ status_code: PAYMENT_STATUSES.STRIPE_ERROR });
      }
    } catch (error) {
      handleError({ status_code: PAYMENT_STATUSES.STRIPE_ERROR });
    } finally {
      setIsRedirectLoading(false);
    }
  };

  const handleError = (error: { error?: string; status_code?: number }) => {
    setLoading(false);
    setError(
      error?.error ||
        PAYMENT_ERROR_MESSAGES[
          error?.status_code || PAYMENT_STATUSES.SERVER_ERROR
        ],
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!name) {
      setError("Name field is required");
      return;
    }

    setError("");
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError({ error });
      return;
    }

    const customerData = getCustomerData({
      initialAmount: originalPrice,
      postgres_plan_id: state?.postgres_plan_id,
      price: state?.price,
    });

    if (customerData) {
      try {
        const { type, clientSecret, user_data, paymentIntentId } =
          await createPayment({
            ...customerData,
            coupon: coupon?.id,
          }).unwrap();

        const url = getUrl({
          stripeId: customerData.price,
          postgresPlanId: customerData.postgres_plan_id,
          initialPriceAmount: customerData.initialAmount,
          percentOff: coupon?.percentOff,
          customParams: {
            uuid: user_data.uuid,
            email: user_data.email,
            first_name: user_data.first_name,
            last_name: user_data.last_name,
            phone_number: user_data.phone_number,
            customer_id: user_data.customer_id,
            matching_session_purchase: paymentIntentId,
          },
        });

        const finishPayment =
          type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

        const { error } = await finishPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url:
              process.env.REACT_APP_PAYMENT_URL + url + "&redirect=true",
            payment_method_data: {
              billing_details: {
                name: name,
              },
            },
          },
          redirect: "if_required", // param to not redirect anywhere after payment and proceed with member creation
        });

        convertPurchase({
          uuid: user_data.uuid,
          planId: user_data.plan_uuid,
          coupon,
          originalPrice,
          tierType: tier?.tier_type,
        });

        const eventName = `member-payment-confirmation-${
          window.location.origin.includes("dev") ? "dev" : "prod"
        }`;

        if (error) {
          trackError(user_data, (error?.message || error) as string, eventName);
          handleError({ error: error?.message });
        } else {
          trackSuccess(user_data, eventName);
          if (paymentIntentId) {
            await createMatchingSessionSubscription(user_data);
          }
          createMember(user_data)
            .unwrap()
            .then(
              () =>
                (window.location.href = REACT_APP_CHECKOUT_COMPLETE_URL + url),
            )
            .catch(() => {
              navigate(NEW_ROUTES.MEMBER_CREATION_ERROR, { state });
              setLoading(false);
            });
        }
      } catch (error: any) {
        handleError(error?.data?.error?.status_code);
      }
    }
  };

  if (isRedirectLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <PaymentDetailsForm
        handleSubmit={handleSubmit}
        error={error}
        name={name}
        onNameChange={(e) => setName(e.target.value)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PaymentForm;
